.toolbar-scroll::-webkit-scrollbar {
  display: none;
}

.toolbar-scroll {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none;
}

.right-overlay {
  background: linear-gradient(270deg, #070d13 0%, rgba(12, 23, 35, 0) 100%);

  height: 40px;
}

.left-overlay {
  background: linear-gradient(270deg, #070d13 0%, rgba(12, 23, 35, 0) 100%);
  transform: matrix(-1, 0, 0, 1, 0, 0);

  height: 40px;
}

.tool-icon-button {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.tool-icon-button > svg {
  height: 22px;
  width: 22px;
}

.tool-button-item {
  width: 56px;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.tool-button-item:hover {
  background-color: #29333E;
}

.tool-button-item[data-active="true"] {
  background-color: #29333E;
  border-bottom: 2px #008280 solid;
}

.menu-item {
  display: flex;
  gap: 4px;
  padding-inline: 10px;
  font-size: 12px;
}

.menu-item:hover {
  background-color: #004746;
}
