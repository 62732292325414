.mobile-list-menu {
  max-height: 32px;
  background-color: #0C1723;
  /* max-width: 100px; */
  overflow: scroll;
  border: 1px solid #29333E;
  border-radius: 6px;
}

.mobile-list-item {
  font-size: 12px;
  color: #F6F7F7;
  line-height: 16px;
  font-weight: 500;
  padding: 8px 16px;
  border-right: 1px solid #29333E;
  white-space: nowrap;
  display: flex;
  align-items: center;
}

.mobile-list-item:hover {
  cursor: pointer;
}

.active-mobile-list-item {
  background-color: #29333E;
}

.icon-mobile-list-item {
  display: flex;
  gap: 5px;
}
